import { FormEvent, useEffect, useState } from 'react';
//import '../styles/Login.css';
import * as Realm from 'realm-web';
import { TextInput } from '@tremor/react';
import { useSignIn, useIsAuthenticated } from 'react-auth-kit';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import PaparazzoLogo from '../../commons/images/svgs/paparazzo_logo_vertical.svg';
import ManzoniLogo from '../../commons/images/manzoni_logo.png';
import { AsyncThunkAction, Dispatch, AnyAction } from '@reduxjs/toolkit';

const Login = () => {
  const app = new Realm.App({ id: 'paparazzoreporter-kroly' });
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/dashboard');
    }
  }, []);

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const credentials = Realm.Credentials.emailPassword(user, password);

    try {
      const user = await app.logIn(credentials);

      //@ts-ignore
      if (user.customData.custom_data.disabled) {
        app.deleteUser(user);
        user.logOut();
        setError('Login fallito: dati mancanti o errati');
        return false;
      }

      if (
        signIn({
          token: user.accessToken || '',
          expiresIn: 1440,
          tokenType: 'Bearer',
          authState: user.customData,
        })
      ) {
        setTimeout(() => {
          navigate('/dashboard');
        }, 300);
      } else {
        setError('Login fallito: qualcosa è andato storto');
        console.log('login failed');
      }
    } catch (err) {
      setError('Login fallito: dati mancanti o errati');
      console.error('Failed to log in', err);
    }
  };

  return (
    <div className="login">
      <form className="form">
        <div className="form__header">
          <img src={PaparazzoLogo} alt="Paparazzo" className="app-logo" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.835"
            height="22.215"
            viewBox="0 0 26.835 22.215"
          >
            <g
              id="Raggruppa_452"
              data-name="Raggruppa 452"
              transform="translate(-696.392 -178.392)"
            >
              <line
                id="Linea_45"
                data-name="Linea 45"
                x2="22.62"
                y2="18"
                transform="translate(698.5 180.5)"
                fill="none"
                stroke="#c8dbea"
                strokeLinecap="round"
                strokeWidth="3"
              />
              <line
                id="Linea_46"
                data-name="Linea 46"
                x2="18"
                y2="22.62"
                transform="translate(721.12 180.5) rotate(90)"
                fill="none"
                stroke="#c8dbea"
                strokeLinecap="round"
                strokeWidth="3"
              />
            </g>
          </svg>
          <img src={ManzoniLogo} alt="AMC Manzoni" className="user-logo" />
        </div>
        <div className="form__body">
          <span className="h1">Accedi</span>

          <TextInput
            name="username"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            placeholder="Username"
            icon={undefined}
            error={false}
            errorMessage=""
            disabled={false}
            maxWidth="max-w-none"
            marginTop="mt-0"
          />
          <input
            type="password"
            autoComplete="current-password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            disabled={false}
          />

          <button className="cta" onClick={handleLogin}>
            Accedi
          </button>

          <div className="form__password-reset">
            <Link to="/user/reset">Imposta o resetta la password</Link>
          </div>
          {error && (
            <div
              className="err--message"
              style={{ color: 'red', marginTop: 20, textAlign: 'center' }}
            >
              <p>{error}</p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;

function dispatch(
  arg0: AsyncThunkAction<
    boolean,
    void,
    {
      state?: unknown;
      dispatch?: Dispatch<AnyAction> | undefined;
      extra?: unknown;
      rejectValue?: unknown;
      serializedErrorType?: unknown;
      pendingMeta?: unknown;
      fulfilledMeta?: unknown;
      rejectedMeta?: unknown;
    }
  >,
) {
  throw new Error('Function not implemented.');
}
