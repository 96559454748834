import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Link, useLocation } from 'react-router-dom';
import {
  fetchCampaigns,
  setOrdersSort,
  setEventFilter,
  selectTxtMongo,
} from './campaignsSlice';
import {
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@tremor/react';
import './Campaigns.css';
import Loader from './loader/Loader';
import { useNavigate } from 'react-router-dom';
import { ILineItem, IOrder } from './PaparazzoTypes';
import { selectLoadStatus } from './campaignsSlice';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { selectUserFavorites, setUserFavorites } from '../users/UsersSlice';

const Campaign = ({ orders }: { orders: Array<IOrder> }) => {
  const dispatch = useAppDispatch();
  const favoriteClients = useAppSelector(selectUserFavorites);
  const campaignLoadStatus = useAppSelector(selectLoadStatus);
  const txtMongo = useAppSelector(selectTxtMongo);
  const [activeCampaignId, setActiveCampaignId] = useState(6129442979);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (campaignLoadStatus === 'idle') {
      if (location.pathname === '/storico') {
        if (!txtMongo || txtMongo === '') {
          dispatch(
            fetchCampaigns({
              limit: 1000,
              forceFetch: true,
              isArchiveQuery: true,
            }),
          );
        }
      } else {
        dispatch(fetchCampaigns({ limit: 1000, status: 'delivering' }));
      }
    }
  }, []);

  const handleLineItemsToggleOnClick = (id: any) => {
    let updateActiveCampaingId;
    activeCampaignId === id
      ? (updateActiveCampaingId = null)
      : (updateActiveCampaingId = id);
    setActiveCampaignId(updateActiveCampaingId);
  };

  const handleOrderOrLineItemClick = (orderId: string, lineItemId?: string) => {
    if (lineItemId) {
      dispatch(
        setEventFilter({
          lineItems: [lineItemId],
        }),
      );
    }
    navigate('/orderpage/' + orderId);
  };

  const handleFavoriteClick = (client: string | undefined) => {
    client && dispatch(setUserFavorites(client));
  };

  return (
    <div className="pzo__table-route campaigns__table bradius-bshadow bg-transparent-white">
      {campaignLoadStatus === 'loading' ? (
        <Loader />
      ) : (
        <Table marginTop="mt-0">
          <TableHead>
            <TableRow>
              <TableHeaderCell>
                <span
                  className="table__head"
                  onClick={() => dispatch(setOrdersSort('END_DATE'))}
                >
                  Stato
                </span>
              </TableHeaderCell>
              <TableHeaderCell>Order / LineItem</TableHeaderCell>
              <TableHeaderCell>Device</TableHeaderCell>
              {location.pathname === '/dashboard' && (
                <TableHeaderCell>Ultima cattura</TableHeaderCell>
              )}
              <TableHeaderCell>
                <span
                  className="table__head"
                  onClick={() => dispatch(setOrdersSort('EVENTS_COUNT_TOTAL'))}
                >
                  Shots
                </span>
              </TableHeaderCell>
              <TableHeaderCell>
                <span
                  className="table__head"
                  onClick={() => dispatch(setOrdersSort('ALPHABETICAL_CLIENT'))}
                >
                  Cliente
                </span>
              </TableHeaderCell>
              {location.pathname !== '/dashboard' && (
                <>
                  <TableHeaderCell>
                    <span
                      className="table__head"
                      onClick={() => dispatch(setOrdersSort('START_DATE'))}
                    >
                      Inizio
                    </span>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <span
                      className="table__head"
                      onClick={() => dispatch(setOrdersSort('END_DATE'))}
                    >
                      Fine
                    </span>
                  </TableHeaderCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order: IOrder, i) => (
              <TableRow key={i}>
                <TableCell>
                  <div className="status">
                    <div
                      className={
                        order.status === 'delivering' ? 'status--active' : ''
                      }
                    ></div>
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    className={
                      activeCampaignId === order.id
                        ? 'order order--line-items__active'
                        : 'order'
                    }
                  >
                    <div className="order__infos">
                      <span
                        id={`line-${i}-order`}
                        data-tooltip-content={order.order}
                        data-tooltip-delay-show={1000}
                        className="order__title"
                        onClick={() =>
                          handleOrderOrLineItemClick(order.id.toString())
                        }
                      >
                        {order.order}
                      </span>
                      {order.order.length > 60 && (
                        <Tooltip anchorId={`line-${i}-order`} />
                      )}
                      <span
                        className="order__toggle"
                        onClick={() => handleLineItemsToggleOnClick(order.id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#16236A"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="order__line-items line-items">
                      <span className="line-items__title">
                        {order.lineItems
                          ? 'Line items'
                          : 'Nessun line item trovato'}
                      </span>
                      {order.lineItems?.map((lineItem: ILineItem, i) => {
                        return (
                          <div className="line-items__item" key={i}>
                            <span
                              onClick={() =>
                                handleOrderOrLineItemClick(
                                  order.id.toString(),
                                  lineItem.lineItem,
                                )
                              }
                            >
                              {lineItem.lineItem.replace(
                                order.order.substring(
                                  0,
                                  order.order.length - 5,
                                ),
                                '',
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="device">
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke={
                        order.deviceEventsCount.desktop > 0
                          ? '#66DBC8'
                          : '#16236A33'
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                      />
                    </svg>

                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke={
                        order.deviceEventsCount.tablet > 0
                          ? '#66DBC8'
                          : '#16236A33'
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 002.25-2.25v-15a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 4.5v15a2.25 2.25 0 002.25 2.25z"
                      />
                    </svg>

                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill={
                        order.deviceEventsCount.mobile > 0
                          ? '#66DBC8'
                          : '#16236A33'
                      }
                    >
                      <path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z" />
                      <path
                        fillRule="evenodd"
                        d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </TableCell>
                {location.pathname === '/dashboard' && (
                  <TableCell>
                    <span>
                      {new Date(order.lastSavedEventTime).toLocaleDateString()}
                      &nbsp;&nbsp;&nbsp;
                      {new Date(order.lastSavedEventTime).toLocaleTimeString()}
                    </span>
                  </TableCell>
                )}
                <TableCell>{order.deviceEventsCount.total || '0'}</TableCell>
                <TableCell>
                  <div className="order__client-name-row">
                    <button
                      className="order__client-name-icon"
                      onClick={() => handleFavoriteClick(order.mnzClientName)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill={
                          favoriteClients.includes(order.mnzClientName || '')
                            ? '#013DEE'
                            : '#c2c6cc'
                        }
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <Link
                      to={`/campagne?cliente=${encodeURIComponent(
                        order.mnzClientName || '',
                      )}`}
                      className="order__client-name"
                      id={`line-${i}-clientname`}
                    >
                      {order.mnzClientName}
                    </Link>
                  </div>
                  {order.mnzClientName && order.mnzClientName.length > 60 && (
                    <Tooltip anchorId={`line-${i}-clientname`} />
                  )}
                </TableCell>
                {location.pathname !== '/dashboard' && (
                  <>
                    <TableCell>
                      <span>
                        {new Date(order.startDate).toLocaleDateString()}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        {new Date(order.endDate).toLocaleDateString()}
                      </span>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default Campaign;
