import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';
import { ChangeEvent, useRef, useState } from 'react';

interface FileUploaderProps {
  accept?: string;
  onFileUpload?: (file: File | null) => void;
}

export interface FileUploaderRef {
  uploadFile: () => Promise<string>;
}

export const FileUploader = forwardRef<FileUploaderRef, FileUploaderProps>(({
  accept = 'image/*',
  onFileUpload,
}, ref) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    return () => {
      if (imageFile) {
        URL.revokeObjectURL(URL.createObjectURL(imageFile));
      }
    };
  }, [imageFile]);

  const handleImageUploadClick = () => {
    fileInputRef.current?.click();
  };

  const updateImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      setUploadSuccess(false);
      if (onFileUpload) {
        onFileUpload(file);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      setImageFile(file);
      setUploadSuccess(false);
      if (onFileUpload) {
        onFileUpload(file);
      }
    }
  };

  const uploadFile = async (): Promise<string> => {
    if (!imageFile) throw new Error('Nessun file selezionato');
    setUploading(true);

    const S3_BUCKET = 'kf-service/paparazzo/uploads';

    try {
      AWS.config.update({
        accessKeyId: 'DO00FVLJ78FHMDVXAHXJ',
        secretAccessKey: 'WyBRlmH9gKbcam9K/sHeqUvm4ql/9i5IfSFDhwFu7uI',
        region: 'fra1',
      });

      const s3 = new S3({
        endpoint: 'https://fra1.digitaloceanspaces.com',
        useAccelerateEndpoint: false,
        s3ForcePathStyle: true,
        signatureVersion: 'v4',
        credentials: new AWS.Credentials(
          'DO00FVLJ78FHMDVXAHXJ',
          'WyBRlmH9gKbcam9K/sHeqUvm4ql/9i5IfSFDhwFu7uI',
        ),
      });

      const fileExtension = imageFile.name.split('.').pop();
      const uniqueFileName = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;

      const params = {
        Bucket: S3_BUCKET,
        Key: uniqueFileName,
        Body: imageFile,
        ContentType: imageFile.type,
        ACL: 'public-read',
        Metadata: {
          'Content-Type': imageFile.type,
          'x-amz-acl': 'public-read'
        }
      };

      await s3.putObject(params).promise();
      setUploadSuccess(true);
      setUploading(false);
      
      const fileUrl = `${S3_BUCKET}/${uniqueFileName}`;
      return fileUrl;
    } catch (error) {
      setUploading(false);
      throw error;
    }
  };

  const imageUrl = imageFile ? URL.createObjectURL(imageFile) : '';

  useImperativeHandle(ref, () => ({
    uploadFile
  }));

  return (
    <div className="file-uploader">
      <div
        className="file-uploader__dropzone"
        onDragOver={handleDragOver}
        onDrop={handleFileDrop}
        onClick={handleImageUploadClick}
      >
        {imageFile ? (
          <div className="file-uploader__uploaded-image">
            <img src={imageUrl} alt={imageFile.name} />
            <span>{imageFile.name}</span>
            {uploading && (
              <div className="file-uploader__loading">
                Caricamento in corso...
              </div>
            )}
            {uploadSuccess && (
              <div className="file-uploader__success">
                Caricamento completato!
              </div>
            )}
          </div>
        ) : (
          <div className="file-uploader__uploaded-preview">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#66DBC8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
              />
            </svg>
            <span>Clicca o trascina per inserire un'immagine</span>
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept={accept}
          style={{ display: 'none' }}
          onChange={updateImage}
        />
      </div>
    </div>
  );
});
