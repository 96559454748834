import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import Campaign from '../../features/campaigns/Campaign';
import {
  selectLastShootedOrders,
  fetchCampaigns,
  setFastSearch,
  setMongoTxtQuery,
} from '../../features/campaigns/campaignsSlice';
import '../styles/Dashboard.css';
import Searchbar from './Searchbar';
import { selectUserFavorites } from '../../features/users/UsersSlice';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const lastShootedOrders = useAppSelector(selectLastShootedOrders);

  useEffect(() => {
    dispatch(setFastSearch(''));
    dispatch(setMongoTxtQuery(''));
  }, [dispatch]);

  const favoriteClients = useAppSelector(selectUserFavorites);


  return (
    <div className="dashboard last-screenshots">
      <div className="dashboard__row">
        <div className="dashboard__col bradius-bshadow bg-blue-gradient">
          <h2 className="h1">Cerca qui una campagna:</h2>
          <Searchbar isFastSearch={false} />
        </div>

        <div className="dashboard__col bradius-bshadow bg-white favorite-clients">
          <h3>Clienti preferiti</h3>
          <div className="favorite-clients__row">
            {favoriteClients.map((favoriteClient) => {
              const urlName = encodeURIComponent(favoriteClient);
              return (
                <Link
                  to={`/campagne?cliente=${urlName}`}
                  className="favorite-clients__client"
                  key={favoriteClient}
                >
                  {favoriteClient}
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <div className="campaigns">
        <div className="campaigns__update">
          <h2>Ultimi screenshot</h2>
          <button
            onClick={() =>
              dispatch(
                fetchCampaigns({
                  limit: 1000,
                  status: 'delivering',
                  forceFetch: true,
                }),
              )
            }
            className="cta"
          >
            Aggiorna
          </button>
        </div>
        <Campaign orders={lastShootedOrders} />
      </div>
    </div>
  );
};

export default Dashboard;
