/*
Per la logica usata nei selectors si veda
https://redux.js.org/usage/deriving-data-selectors
e
https://react-redux.js.org/api/hooks
*/

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { suspendUser, getUsers, newUser, updateUser } from './UsersAPI';

export interface User {
  id: number;
  custom_data: {
    role?: string;
    name: string;
    disabled?: boolean;
  };
  data: {
    email: string;
  };
  mongo?: any;
}

export interface UserState {
  list: Array<User>;
  status: string;
  error: string;
  currentUser?: User;
  sorting: string;
  sortingOrder: string;
  userFilter: string;
  userFavorites: string[];
}

const initialState: UserState = {
  list: [],
  status: 'idle',
  sorting: 'NAME',
  error: '',
  sortingOrder: 'ASC',
  userFilter: '',
  userFavorites: (() => {
    if (typeof localStorage !== 'undefined') {
      try {
        const storedFavorites = localStorage.getItem('userFavorites');
        return storedFavorites ? JSON.parse(storedFavorites) : [];
      } catch (e) {
        console.error('Errore nel parsing dei preferiti dal localStorage');
        return [];
      }
    }
    return [];
  })(),
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await getUsers();
  return response;
});

export const createUser = createAsyncThunk(
  'users/createUser',
  async (data: User) => {
    try {
      const response = await newUser(data);

      return response;
    } catch (e) {
      throw new Error('Error creating user');
    }
  },
);

export const disableUser = createAsyncThunk(
  'users/disableUser',
  async (email: string) => {
    try {
      const response = await suspendUser(email);

      return response;
    } catch (e) {
      throw new Error('Error creating user');
    }
  },
);

export const editUser = createAsyncThunk(
  'users/editUser',
  async (userObj: User) => {
    try {
      const response = await updateUser(userObj);

      return response;
    } catch (e) {
      throw new Error('Error creating user');
    }
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<string>) => {
      if (state.sorting === action.payload) {
        state.sortingOrder = state.sortingOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        state.sorting = action.payload;
      }
    },
    setSortingOrder: (state, action: PayloadAction<string>) => {
      state.sortingOrder = action.payload;
    },
    setUserFilter: (state, action: PayloadAction<string>) => {
      state.userFilter = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    setUserFavorites: (state, action: PayloadAction<string>) => {
      const isLocalStorageAvailable = typeof localStorage !== 'undefined';
      
      let storedFavorites: string[] = [];
      if (isLocalStorageAvailable) {
        const storedFavoritesString = localStorage.getItem('userFavorites');
        if (storedFavoritesString) {
          try {
            storedFavorites = JSON.parse(storedFavoritesString);
          } catch (e) {
            console.error('Errore nel parsing dei preferiti dal localStorage');
          }
        }
      }
  
      if (state.userFavorites.length === 0 && storedFavorites.length > 0) {
        state.userFavorites = storedFavorites;
      }
      
      let newFavoritesArray = state.userFavorites.includes(action.payload)
        ? state.userFavorites.filter((favorite) => favorite !== action.payload)
        : [...state.userFavorites, action.payload];
      
      state.userFavorites = newFavoritesArray;
      
      if (isLocalStorageAvailable) {
        localStorage.setItem('userFavorites', JSON.stringify(newFavoritesArray));
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createUser.pending, (state) => {
        state.error = '';
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'idle';
        //TODO: FARLO CON IL ROUTER!!
        //@ts-ignore
        if (action.payload.error) {
          //@ts-ignore
          console.log(action.payload.error);
          //@ts-ignore
          state.error = action.payload.error;
        } else {
          window.location.href = `/admin/utenze?user=${action.payload}`;
        }
      })
      .addCase(createUser.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(editUser.pending, (state) => {
        state.error = '';
        state.status = 'loading';
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.status = 'idle';
        //TODO: FARLO CON IL ROUTER!!
        //@ts-ignore
        if (action.payload.error) {
          //@ts-ignore
          console.log(action.payload.error);
          //@ts-ignore
          state.error = action.payload.error;
        } else {
          window.location.href = `/admin/utenze?user=${action.payload}`;
        }
      })
      .addCase(editUser.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(disableUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        state.status = 'idle';
        //TODO: FARLO CON IL ROUTER!!
        window.location.href = '/admin/utenze';
      })
      .addCase(disableUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setSorting,
  setSortingOrder,
  setUserFilter,
  setCurrentUser,
  setUserFavorites,
} = usersSlice.actions;

const selectUsersStateList = (state: RootState) => state.users.list;

export const selectUserFavorites = (state: RootState) =>
  state.users.userFavorites;
export const selectSorting = (state: RootState) => state.users.sorting;
export const selectSortingOrder = (state: RootState) =>
  state.users.sortingOrder;
export const selectUserFilter = (state: RootState) => state.users.userFilter;

export const selectUsersList = createSelector(
  [selectUsersStateList, selectSorting, selectSortingOrder, selectUserFilter],
  (usersList, sorting, order, userFilter) =>
    [...usersList]
      .filter(
        (user: User) =>
          user.custom_data.name.toLowerCase().includes(userFilter) ||
          user.data.email.includes(userFilter),
      )
      .sort((a, b) => {
        switch (sorting) {
          case 'NAME':
            return order === 'ASC'
              ? a.data.email.localeCompare(b.data.email)
              : b.data.email.localeCompare(a.data.email);
          case 'EMAIL':
            return order === 'ASC'
              ? a.data.email.localeCompare(b.data.email)
              : b.data.email.localeCompare(a.data.email);
          case 'ROLE':
            return order === 'ASC'
              ? a.data.email.localeCompare(b.data.email)
              : b.data.email.localeCompare(a.data.email);
          default:
            return 1;
        }
      }),
);

const selectUserId = (state: RootState, userId: number) => userId;
export const selectStatus = (state: RootState) => state.users.status;
export const selectError = (state: RootState) => state.users.error;

export const selectUser = createSelector(
  [selectUsersList, selectUserId],
  (usersList, userId) => usersList.filter((user) => user.id === userId),
);

export const selectCurrentUser = (state: RootState) => state.users.currentUser;

export default usersSlice.reducer;
