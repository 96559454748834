import axios from 'axios';
import { IOrder } from '../PaparazzoTypes';
import { useState, useRef } from 'react';
import { SelectBox, SelectBoxItem } from '@tremor/react';
import { FileUploader, FileUploaderRef } from '../fileUploader/FileUploader';

interface UploadState {
  orderId: number;
  type: string;
  device: string;
  placement: string;
  urlClippedBase: string;
  isIntro: boolean;
  isSkin: boolean;
  testata: string;
  lineItem: string;
  selectedFile: File | null;
}

const ModalUploadScreenshot = ({
  orderId,
  testate,
  orderDetail,
}: {
  orderId: string;
  testate: IOrder[];
  orderDetail: IOrder;
}) => {
  const [uploadState, setUploadState] = useState<UploadState>({
    orderId: +orderId,
    type: 'DISPLAY',
    device: 'DESKTOP',
    placement: 'Middle1',
    urlClippedBase: '',
    isIntro: false,
    isSkin: false,
    testata: `${testate[0]?.testata}` || '',
    lineItem: `${orderDetail.lineItems[0]?.lineItem}` || '',
    selectedFile: null,
  });

  const fileUploaderRef = useRef<FileUploaderRef>(null);

  const handleFileUpload = (file: File | null) => {
    setUploadState((prev) => ({ ...prev, selectedFile: file }));
  };

  const handleSubmit = async () => {
    if (
      !uploadState.selectedFile ||
      !uploadState.testata ||
      !uploadState.lineItem
    ) {
      alert('Per favore compila tutti i campi obbligatori');
      return;
    }

    try {
      const fileUrl = await fileUploaderRef.current?.uploadFile();
      if (!fileUrl) throw new Error('Errore nel caricamento del file');

      const { selectedFile, ...eventData } = {
        ...uploadState,
        urlClippedUI: fileUrl,
      };

      const encodedData = btoa(JSON.stringify(eventData));
      const response = await axios.get(
        `https://eu-central-1.aws.data.mongodb-api.com/app/paparazzoreporter-kroly/endpoint/copy/upload_event?event=${encodedData}`
      );

      if (response.status === 200) {
        alert('Screenshot caricato con successo!');
      } else {
        throw new Error('Errore nella risposta del server');
      }
    } catch (error) {
      console.error('Errore durante il caricamento:', error);
      alert('Si è verificato un errore durante il caricamento');
    }
  };

  return (
    <div className="modal-upload">
      <div className="modal-upload__row">
        <div className="">
          <div className="modal-upload__header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
              />
            </svg>
            <h3>Upload Screenshot</h3>
          </div>
          <div className="modal-upload__uploader">
            <FileUploader
              ref={fileUploaderRef}
              onFileUpload={handleFileUpload}
            />
          </div>
        </div>
        <div className="modal-upload__content">
          <div className="modal-upload__info">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
            <span>
              Compila i campi richiesti e clicca sul pulsante "Carica" per
              completare l'upload
            </span>
          </div>
          <form
            className="modal-upload__form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="modal-upload__form-row">
              <label>
                <span>*</span>Testata
              </label>
              <SelectBox
                defaultValue={uploadState.testata}
                onValueChange={(value: string) =>
                  setUploadState((prev) => ({ ...prev, testata: value }))
                }
              >
                {testate?.map((testata) => (
                  <SelectBoxItem
                    key={testata.id}
                    value={testata.testata}
                    text={testata.testata || ''}
                  />
                ))}
              </SelectBox>
            </div>
            <div className="modal-upload__form-row">
              <label>
                <span>*</span>Line item
              </label>
              <SelectBox
                value={uploadState.lineItem}
                onValueChange={(value: string) =>
                  setUploadState((prev) => ({ ...prev, lineItem: value }))
                }
              >
                {orderDetail.lineItems.map((lineItem) => (
                  <SelectBoxItem
                    key={lineItem.id}
                    value={lineItem.lineItem}
                    text={lineItem.lineItem || ''}
                  />
                ))}
              </SelectBox>
            </div>
            <div className="modal-upload__form-row">
              <label>
                <span>*</span>Placement
              </label>
              <SelectBox
                defaultValue={uploadState.placement}
                onValueChange={(value: string) =>
                  setUploadState((prev) => ({ ...prev, placement: value }))
                }
              >
                <SelectBoxItem value="Middle1" text="Middle1" />
                <SelectBoxItem value="Middle2" text="Middle2" />
                <SelectBoxItem value="Middle3" text="Middle3" />
                <SelectBoxItem value="TopLeft" text="TopLeft" />
              </SelectBox>
            </div>
            <div className="modal-upload__form-row">
              <label>
                <span>*</span>Tipologia
              </label>
              <SelectBox
                defaultValue={uploadState.type}
                onValueChange={(value: string) =>
                  setUploadState((prev) => ({ ...prev, type: value }))
                }
              >
                <SelectBoxItem value="DISPLAY" text="Display" />
                <SelectBoxItem value="PREROLL" text="Preroll" />
              </SelectBox>
            </div>
            {/* <div className="modal-upload__form-row">
              <label>
                <span>*</span>Data e Ora
              </label>
              <div className="modal-upload__datetime">
                <div className="modal-upload__datepicker">
                  <DateRangePicker
                    enableDropdown={false}
                    value={uploadState.date ? [uploadState.date] : undefined}
                    onValueChange={(dates) => {
                      const selectedDate = dates?.[0] || null;
                      console.log('Selected date:', selectedDate);
                      setUploadState((prev) => ({
                        ...prev,
                        date: selectedDate,
                      }));
                    }}
                    placeholder="Seleziona data"
                  />
                </div>
                <div className="modal-upload__timepicker">
                  <input
                    type="time"
                    value={uploadState.time}
                    onChange={(e) => 
                      setUploadState(prev => ({ ...prev, time: e.target.value }))
                    }
                    className="tremor-input"
                  />
                </div>
              </div>
            </div> */}
            <div className="modal-upload__form-row">
              <label></label>
              <div style={{ display: 'flex', gap: '20px' }}>
                <label style={{ minWidth: 'auto' }}>
                  <input
                    type="checkbox"
                    checked={uploadState.isIntro}
                    onChange={(e) =>
                      setUploadState((prev) => ({
                        ...prev,
                        isIntro: e.target.checked,
                      }))
                    }
                  />{' '}
                  Intro
                </label>
                <label style={{ minWidth: 'auto' }}>
                  <input
                    type="checkbox"
                    checked={uploadState.isSkin}
                    onChange={(e) =>
                      setUploadState((prev) => ({
                        ...prev,
                        isSkin: e.target.checked,
                      }))
                    }
                  />{' '}
                  Skin
                </label>
              </div>
            </div>
          </form>
          <div className="modal-upload__footer">
            <button
              className="cta"
              onClick={handleSubmit}
              disabled={
                !uploadState.selectedFile ||
                !uploadState.testata ||
                !uploadState.lineItem
              }
            >
              Carica
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadScreenshot;
